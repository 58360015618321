"use client";

import { useTranslations } from "next-intl";
import Image from "next/image";
import {
  FaEnvelope,
  FaInstagram,
  FaPhone,
  FaTwitter,
  FaWhatsapp,
} from "react-icons/fa";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { SiteDetail } from "../../interfaces/site-model";
import styles from "./Footer.module.scss";
import { Link } from "../../i18n/routing";

const siteMenu = [
  {
    path: "/",
    label: "home",
  },
  {
    path: "/about-us",
    label: "about us",
  },
  // {
  //   path: "/our-services",
  //   label: "our services",
  // },
  {
    path: "/our-offers",
    label: "our offers",
  },
  {
    path: "/our-blogs",
    label: "our blogs",
  },
  {
    path: "/terms-and-conditions",
    label: "tc",
  },
  {
    path: "/contact-us",
    label: "contact us",
  },
];

interface FooterProps {
  data: SiteDetail;
}

const Footer = ({ data }: FooterProps) => {
  const t = useTranslations("footer");

  let address = `${data?.address?.address_line}, ${data?.address?.city}, ${data?.address?.country}`;

  return (
    <footer className={styles.footer}>
      <div className={`container ${styles.footer__container}`}>
        <div className={styles.topbar}>
          <div className={styles.topbar__logo_container}>
            <Image
              src={data?.site_logo?.data?.attributes?.url}
              alt={data?.site_logo?.data?.attributes?.alternativeText || "logo"}
              fill
            />
          </div>
          <div className={styles.topbar__contact}>
            <Link
              href={`mailto:${data?.contact_details?.email}`}
              className={styles.topbar__contact__item}
            >
              {/* info@privatefleetservices.com */}
              {data?.contact_details?.email}
              <span>
                <FaEnvelope />
              </span>
            </Link>
            <Link
              href={`tel:${data?.contact_details?.phone_number}`}
              className={styles.topbar__contact__item}
            >
              {/* +966920003455 */}
              {data?.contact_details?.phone_number}
              <span>
                <FaPhone />
              </span>
            </Link>
            <p className={styles.topbar__contact__item}>
              {/* {t("address")} */}
              {address}
              <span>
                <HiOutlineLocationMarker />
              </span>
            </p>
          </div>
        </div>
        <div className={styles.site_menu}>
          {siteMenu.map((menu, index) => {
            return (
              <Link href={menu.path} key={index} className={styles.item}>
                {t(menu.label)}
              </Link>
            );
          })}
        </div>
        <div className={styles.copyright}>
          <p className={styles.copyright__text}>
            {/* {t("copyright")} */}
            {data?.copyright_note}
          </p>
          <div className={styles.copyright__social}>
            <Link
              href={`tel:${data?.contact_details?.phone_number}`}
              target="_blank"
              className={styles.copyright__social__item}
            >
              <FaPhone />
            </Link>
            <Link
              href={`https://wa.me/${data?.contact_details?.whatsapp_number}`}
              target="_blank"
              className={styles.copyright__social__item}
            >
              <FaWhatsapp />
            </Link>
            <Link
              href={data?.social_links?.instagram_link || ""}
              target="_blank"
              className={styles.copyright__social__item}
            >
              <FaInstagram />
            </Link>
            <Link
              href={data?.social_links?.twitter_link || ""}
              target="_blank"
              className={styles.copyright__social__item}
            >
              <FaTwitter />
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
