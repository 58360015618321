import(/* webpackMode: "eager" */ "/tmp/build_bab1841f/app/globals.scss");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/tmp/build_bab1841f/app/google-tag-manager.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/tmp/build_bab1841f/components/BottomFixedMenus/BottomFixedMenus.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/tmp/build_bab1841f/components/Footer/Footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/tmp/build_bab1841f/components/Navbar/Navbar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/tmp/build_bab1841f/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/tmp/build_bab1841f/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["NextUiProviders"] */ "/tmp/build_bab1841f/providers/NextUiProvider.tsx");
